import React, { Component, createRef } from 'react'

import styled from 'styled-components'

import Layout from '../components/layout'

import { MainSectionWrapper, PageHeader } from '../components/common/styles'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    grid-row: 3 / 5;
`

const PressColumn = styled.div`
    width: ${props => props.width};

    & > img {
        margin-bottom: 0;
    }
`;

class PressPage extends Component {
    scrollRef = createRef()

    componentDidMount = () => {
        window.addEventListener('keydown', this.scroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.scroll)
    }

    scroll = (e) => {
        switch (e.key) {
            case "ArrowDown":
                this.scrollRef.current.scrollBy(0, 30)
                break
            case "ArrowUp":
                this.scrollRef.current.scrollBy(0, -30)
                break
        }
    }

    render() {
        return (
            <Layout>
                <MainSectionWrapperOverride ref={this.scrollRef}>
                    <PageHeader
                        style={{
                            color: '#83ccb8',
                        }}
                    >
                        Press
                    </PageHeader>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <PressColumn width="55%">
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press5.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press12.JPG" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press10.PNG" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press3.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press4.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press15.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press16.png" alt="" />
                        </PressColumn>
                        <PressColumn width="45%">
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press6.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press7.png" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press9.PNG" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press1.jpg" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press13.jpg" alt="" />
                            <img src="https://s3.amazonaws.com/valentina-site/press_images/press14.jpg" alt="" />
                        </PressColumn>
                    </div>
                </MainSectionWrapperOverride>
            </Layout>
        )
    }
}

export default PressPage